<template>
  <div class="cart">

    <NavHeader />

    <div class="content_container content">
      <el-card class="el-card">

        <el-row>
          <el-col :span="6">
            <div class="tips">
              <dl>
                <dt>
                  <img :src='$config.baseUrl+productInfo.goods_img_path'>
                </dt>
                <dd>
                  <p> {{productInfo.goods_name}}</p>

                  <p> 议价范围：{{productInfo.max_price}}-{{productInfo.min_price}}</p>
                </dd>

              </dl>

            </div>
          </el-col>
          <el-col :span="18">

            <div class="box">
              <h1> 订单提交成功！</h1>

              注意：<br>
              本价格非实际销售价。<br>
              提交订单后，请联系卖家修改价格:<a
                style="color: red"
                target="_blank"
                :href="'http://wpa.qq.com/msgrd?v=3&uin='+shop.kefu_qq+'&site=qq&menu=yes'"
              >

                点击进入QQ交谈

              </a>或者电话联系商家：{{shop.shop_tel}}<br>
              在确认订单金额无误后，再进行支付。（去"<span
                class="red"
                @click="$router.push('/member-order')"
              >我的订单 </span>"支付）<br>
            </div>

          </el-col>
          <el-col :span="24">

            <p class="btn">
              <el-button
                @click="$router.push('/')"
                block
                shape="circle"
                style="margin-top: 30px"
              >
                返回首页
              </el-button>

              <el-button
                @click="$router.push('/member-order')"
                block
                shape="circle"
                style="margin-top: 30px"
              >
                查看我的订单
              </el-button>
            </p>
          </el-col>
        </el-row>
      </el-card>

    </div>

  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      ruleForm: {
        content: "",
        name: "",
        phone: "",
        num: ""
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "change" }],
        phone: [
          { required: true, message: "请输入电话", trigger: "change" },
          {
            pattern: this.$validate.mobile,
            message: "请输入正确的电话",
            trigger: "change"
          }
        ],
        num: [
          { required: true, message: "请输入产品需求数量", trigger: "change" }
          // {
          //   pattern: this.$validate.number,
          //   message: "请输入数字",
          //   trigger: "change"
          // }
        ]
      },

      shop: {
        kefu_qq: "252738932"
      },
      productInfo: "",

      shopid: "",
      member_id: "",
      goodname: "",
      goodid: "",
      shopname: "",
      consult_content: "",
      member_name: ""
    };
  },
  computed: {},
  mounted() {
    this.getDetail();
  },
  methods: {
    // 获取产品信息
    getDetail() {
      this.$get("home/goods/" + this.$route.query.id, 1).then(res => {
        this.productInfo = res.result;
        console.log("  this.productInfo", this.productInfo);

        this.$get("seller/shop/" + res.result.goods_shop_id).then(res => {
          console.log("shop", res);
          this.shop = res;
        });
      });
    },
    summit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let data = {
            zx_type: 1,
            goodid: this.productInfo.goods_id,
            goodname: this.productInfo.goods_name,
            shopid: this.productInfo.get_shop.shop_id,
            shopname: this.productInfo.get_shop.shop_name,
            name: this.ruleForm.name,
            phone: this.ruleForm.phone,
            num: this.ruleForm.num,
            member_id: this.$store.state.member.id,
            member_name: this.$store.state.member.name,
            content: this.ruleForm.content
          };
          this.$post("home/goodmessage", data).then(res => {
            this.$message.success("提交成功");
            this.$router.push({
              path: "/detail",
              query: { id: this.$route.query.id }
            });
          });
        } else {
          return false;
        }
      });
    }
  },
  components: {
    NavHeader
  },
  created() {}
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.red {
  color: red;
  padding: 0 10px;
  cursor: pointer;
}
.btn {
  text-align: center;
}
.el-card {
  ul {
    background: #eee;
    padding: 10px 20px;
  }
  .box {
    padding: 10px 20px;
    font-size: 14px;
  }

  margin-bottom: 30px;
  line-height: 30px;

  h1 {
    font-size: 18px;
    color: red;
    margin-bottom: 20px;
  }
}
.content {
  margin-top: 10px !important;
}
.tips {
  margin-bottom: 30px;

  dl {
    width: 200px;
    padding: 0;
    margin: 0;

    dt {
      width: 200px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    dd {
      box-sizing: border-box;
      flex: 1;
      p:nth-child(1) {
        font-size: 14px;
        line-height: 22px;
        font-weight: bold;
      }
    }
  }
  ul {
    background: #eee;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    margin-left: 30px;
    box-sizing: border-box;
    line-height: 40px;
    color: #666;

    box-sizing: border-box;
  }
}
// ::v-deep .el-textbox .txt-area textarea {
//   height: 2rem !important;
// }
// ::v-deep .el-row {
//   align-items: baseline;
//   display: flex;
//   margin-bottom:20px;
// }
.flex-content {
  line-height: 30px;
  text-align: center;
}
.my {
  height: 100%;
}
.content {
  background: #fff;
  padding: 20px;
  box-sizing: 0;
}
</style>
 